@import "src/styles/variable.scss";
body {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

.ant-message {
    top: toPx(200) !important;
}

.ant-switch:focus:hover {
    box-shadow: none !important
}

.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #CDCDCA !important;
}

.ant-message-custom-content,
.anticon-check-circle {
    font-size: toPx(32) !important;
}

.ant-switch-checked:focus {
    box-shadow: none !important;
}
input, button, select, optgroup, textarea {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

@media screen and (min-width: 1366px) {
    .ant-message {
        top: toPx(100) !important;
    }

    .ant-message-custom-content,
    .anticon-check-circle {
        font-size: toPx(12) !important;
    }

    .MuiTooltip-tooltip {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 1920px) {
    .ant-message {
        top: toPx(100) !important;
    }

    .ant-message-custom-content,
    .anticon-check-circle {
        font-size: toPx(16) !important;
    }

    .MuiTooltip-tooltip {
        font-size: 16px !important;
    }
}

@media screen and (min-width: 2560px) {
    .ant-message {
        top: toPx(180) !important;
    }

    .ant-message-custom-content,
    .anticon-check-circle {
        font-size: toPx(21) !important;
    }

    .MuiTooltip-tooltip {
        font-size: 20px !important;
    }
}

@media screen and (min-width: 3840px) {
    .ant-message {
        top: toPx(200) !important;
    }

    .ant-message-custom-content,
    .anticon-check-circle {
        font-size: toPx(32) !important;
    }

    .MuiTooltip-tooltip {
        font-size: 32px !important;
    }
}
:where(.css-dev-only-do-not-override-apn68).ant-select .ant-select-selection-placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
    color: #bfbfbf;
    pointer-events: none;
}
.ant-modal-content{
    padding: 0;
}
:where(.css-dev-only-do-not-override-apn68).ant-modal .ant-modal-body{
    padding: 24px;
}